@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins Regular";
  src: url("/public//poppinsFont/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins Bold";
  src: url("/public//poppinsFont/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("/public/poppinsFont/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins Medium";
  src: url("/public/poppinsFont/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins Light";
  src: url("/public//poppinsFont/Poppins-Light.ttf");
}

/* Font types for the tags (elements)*/

/* input {
  font-family: "Poppins Light";
}

h6,
span {
  font-family: "Poppins Regular";
}

h3 {
  font-family: "Poppins Medium";
}

h4 {
  font-family: "Poppins SemiBold";
}

p {
  font-family: "Poppins Light";
} */

/* Font variants */

.font-light {
  font-family: "Poppins Light";
}

.font-regular {
  font-family: "Poppins Regular";
}

.font-medium {
  font-family: "Poppins Medium";
}

.font-semibold {
  font-family: "Poppins SemiBold";
}

.font-bold {
  font-family: "Poppins Bold";
}

/* Hide scroll bar */

.hide-scroll-bar {
  overflow: scroll;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.hide-scroll-bar {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

/* rating star */

.filvtC{
  padding: 0!important;
}

/* Tailwind CSS components */

@layer components {
  .main-header {
    @apply text-[24px];
  }

  .sub-header {
    @apply text-[18px];
  }

  .normal-text {
    @apply text-[16px];
  }

  .small-text {
    @apply text-[14px];
  }

  .sub-text {
    @apply text-[0.8rem];
  }

  .price-text {
    @apply text-[1.1rem];
  }

  .viewAllText {
    @apply flex items-center text-[14px] md:text-[16px] font-regular text-primary;
  }

  .sliderBookCard {
    @apply cursor-pointer shadow-sm;
  }

  .booksGrid {
    @apply grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-5;
  }

  .singleBookSectionGrid {
    @apply grid gap-y-5 gap-x-5 grid-cols-1 sm:grid-cols-2 ipadMini:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3;
  }

  .bookMoreInfoHeader {
    @apply w-[150px];
  }

  .bookMoreInfoData {
    @apply text-textColor;
  }

  .transparentButton {
    @apply bg-transparent text-[16px] px-[22px] py-[12px] rounded-md;
  }

  .primaryButton {
    @apply text-[16px] px-[22px] py-[12px] rounded-md font-medium bg-primary text-white;
  }

  .outlineButton {
    @apply text-[16px] px-[22px] py-[12px] rounded-md font-medium border border-primary text-primary hover:text-white hover:bg-primary;
  }

  .transparentButton {
    @apply text-[16px] px-[22px] py-[12px] rounded-md font-medium text-primary;
  }

  .inputLabel {
    @apply sub-header tracking-[-0.02rem] font-medium text-neutral-800;
  }

  .inputField {
    @apply w-full px-2 py-3 mt-2 text-[16px] text-neutral-700 font-regular rounded-md outline-none border border-gray-200 active:border-primary hover:border-primary;
  }
}


/* Swiper custom CSS */

/*  for arrows */
div.swiper-button-prev,
div.swiper-button-next {
color: #ec1c24;
}

/*  and for bullets */
span.swiper-pagination-bullet {
background-color: #ec1c24;
}


.card-book-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

